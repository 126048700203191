import * as React from 'react';
import Page from 'components/Page';
import CustomInput from '../components/CustomInput'

// Reducer & Action
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/core";
import { connect } from 'react-redux';
import validarCpf from 'validar-cpf';
import * as loginSelectors from 'store/login/reducer';
import { Form, FormGroup, Col, Row, Button } from 'reactstrap';
import ShowTickets from '../components/ShowTickets';
import { MdCheck } from 'react-icons/lib/md';
import BaseService from '../services/BaseService';
import MessageBoxModal from '../components/MessageBoxModal';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: #2ba75b;
    text-align: center;
    position: absolute;
    left: 45%;
    z-index: 999;
    margin-top: 15%;
`;
class RechargePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            id: 0,
            document: '',
            document_invalid: false,
            amount: 0,
            amount_invalid: false,
            full_name: '',
            email: '',
            step: 1,
            ticket: null,
            ticketInvalid: false,
            show_modal: false,
            error_message: '',
            loading: false,
            vehicle_type : {id : 1}
        }
        this.toggle = this.toggle.bind(this);
        this.FormInsert = () => (false);
    }
    handleChange = (event, value, maskedValue) => {
      this.setState({ amount: value })
    }
    handlerOnChange = (name, value) => {
      this.setState({
        [name]: value
      })
    }
    toggle(refresh){
        this.setState({
            show_modal: refresh,
            error_message: ''
        })
    }
    setRecharge(){
        this.setState({loading: true})
        let url = BaseService.ENDPOINT + 'access-point/buy-credit/driver';
        var form = new FormData();
        form.append("user_id", this.state.id);
        form.append("credit_id", this.state.ticket.id);
        return fetch(url, {
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Authorization' : 'Bearer ' + this.props.user.access_token,
        }),
        body : form
        })
        .then((response) => response.json())
        .then((json) => {
        if(json.return.code === 1){
            this.setState({
                loading: false,
                step: 3
            })
        }else if(json.return.code === '-403'){
            window.location = '/';
        }else{
            this.setState({
                loading: false,
                show_modal: true,
                error_message: json.return.message
            })
            return 0;
        }
        });
    }
    getDetails(){ 
        this.setState({loading: true})
        let url = BaseService.ENDPOINT + 'access-point/confirm-info';
        var form = new FormData();
        form.append("document", this.state.document);
        form.append("credit_id", this.state.ticket.id);
        return fetch(url, {
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Authorization' : 'Bearer ' + this.props.user.access_token,
        }),
        body : form
        })
        .then((response) => response.json())
        .then((json) => {
        if(json.return.code === 1){
            this.setState({
                id: json.data.user.id,
                full_name: json.data.user.name,
                email: json.data.user.email,
                document: json.data.user.document,
                loading: false,
                step: this.state.step +1
            })
        }else if(json.return.code === '-403'){
            window.location = '/';
        }else{
            this.setState({
                loading: false,
                show_modal: true,
                error_message: json.return.message
            })
            return 0;
        }
        });
    }
    isValidate(){
        if(this.state.step === 1){
            var errors = 0;
            const { document } = this.state;
            var document_cpf = document.replace('-', '').replace('.', '').replace('.', '');
            if(validarCpf(document_cpf) === false){
                this.setState({ document_invalid: true });
                errors++;
            }
            else{
                this.setState({ document_invalid: false })
            }
            if(this.state.ticket === null || this.state.ticket.length<0) {
              errors++;
              this.setState({ticketInvalid: true});
            } else {
              this.setState({ticketInvalid: false});
            }
            if(errors === 0){
                this.getDetails();
            }
        }
        else{
            this.setRecharge();
        }
    }
    render(){
        const { step } = this.state;
        const pageIndex = () => {
            switch (step) {
                case 1:
                    return(
                        <Row>
                            <Col></Col>
                            <Col md={10}>
                            <div className="sweet-loading">
                                <PulseLoader
                                css={override}
                                size={15}
                                //size={"150px"} this also works
                                color={"#2ba75b"}
                                loading={this.state.loading}
                                />
                            </div>
                            <Form 
                                hidden={this.state.loading}
                            >
                                <Row>
                                    <Col lg="4" md="4" sm="0" xs="0"></Col>
                                    <Col lg="4" md="4" sm="12" xs="12">
                                        <FormGroup>
                                            <CustomInput
                                                placeholder="CPF"
                                                mask="000.000.000-00"
                                                invalid={this.state.document_invalid}
                                                invalid_message="CPF inválido"
                                                value={this.state.document}
                                                onChange={(value) => { this.handlerOnChange('document', value) }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4" md="4" sm="0" xs="0"></Col>
                                </Row>
                                <ShowTickets
                                    sell={true}
                                    user={this.props.user}
                                    vehicle_type={this.state.vehicle_type}
                                    FormInsert={this.FormInsert}
                                    ticketInvalid={this.state.ticketInvalid}
                                    ticket={(id) => this.setState({ticket: id})}
                                    deleteAction={this.deleteAction}
                                    //formFeedbackMsg={Strings.required_field}
                                    reload={this.state.reload}
                                />
                                <FormGroup style={{textAlign: 'center'}}>
                                    <Button className="button-store-credit-card" color="primary" onClick={() => this.isValidate()}>Continuar</Button>
                                </FormGroup>
                            </Form>
                            </Col>
                            <Col></Col>
                        </Row>
                    )
                
                    case 2:
                        return(
                            <Row>
                                <Col md="3" sm="0" xs="0"></Col>
                                <Col md={6}>
                                    <div className="sweet-loading">
                                        <PulseLoader
                                        css={override}
                                        size={15}
                                        //size={"150px"} this also works
                                        color={"#2ba75b"}
                                        loading={this.state.loading}
                                        />
                                    </div>
                                <Form
                                    hidden={this.state.loading}
                                >
                                    <h5 style={{textAlign: "center"}}>Confirme os dados</h5>
                                    <br/>
                                    <FormGroup>
                                        <CustomInput
                                            disabled
                                            label="Name"
                                            value={this.state.full_name}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <CustomInput
                                            disabled
                                            label="Email"
                                            value={this.state.email}
                                        />
                                    </FormGroup>
                                    {/* <FormGroup>
                                        <CustomInput
                                            disabled
                                            label="Telefone"
                                            //value={this.state.full_name}
                                            value="(11) 98563-2531"
                                        />
                                    </FormGroup> */}
                                    <FormGroup>
                                        <CustomInput
                                            disabled
                                            label="CPF"
                                            mask="000.000.000-00"
                                            value={this.state.document}
                                            onChange={(value) => { this.handlerOnChange('document', value) }}
                                        />
                                    </FormGroup>
                                    <Row style={{textAlign: 'center'}}>
                                        <center style={{position: 'relative', left: '20%', margin: '7px'}}>
                                            <div class="box-select-ticket ">
                                                <label class="ticket">
                                                        {this.state.ticket !== null ? this.state.ticket.description : ''}
                                                    <br/>
                                                    <span style={{color: "gray"}}>
                                                        {this.state.ticket !== null ? this.state.ticket.vehicle_type_id : ''}
                                                    </span>
                                                </label>
                                                <label class="ticket-value">R$ {this.state.ticket !== null ? parseInt(this.state.ticket.value, 10).toFixed(2) : ''}</label>
                                            </div>
                                        </center>
                                    </Row>
                                    <br/>
                                    <Row style={{textAlign: 'center'}}>
                                        <Col>
                                            <h6>Os dados estão corretos?</h6>
                                        </Col>
                                    </Row>
                                    <Row style={{textAlign: "center"}}>
                                        <Col>
                                            <Button className="button-store-credit-card" color="danger" onClick={() => this.setState({step: 1, ticket: null})}>Não</Button>
                                        </Col>
                                        <Col>
                                            <Button className="button-store-credit-card" color="primary" onClick={() => this.isValidate()}>Sim</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                </Col>
                                <Col md="3" sm="0" xs="0"></Col>
                            </Row>
                        )
                case 3:
                    return(
                        <Row>
                            <Col md="4" sm="0" xs="0"></Col>
                            <Col md="4" sm="0" xs="0">
                                <div className="sweet-loading">
                                    <PulseLoader
                                    css={override}
                                    size={15}
                                    //size={"150px"} this also works
                                    color={"#2ba75b"}
                                    loading={this.state.loading}
                                    />
                                </div>
                                <center>
                                    <MdCheck size={100} color="green"/>
                                </center>
                                <br/>
                                <h5 style={{textAlign: "center"}}>
                                    Recarga Concluida com sucesso
                                </h5>
                            </Col>
                            <Col md="4" sm="0" xs="0"></Col>
                        </Row>
                    )
                default:
                    break;
            }
        }
        return(
            <Page
                title="Recarga"
                className="recharge-page">
                    {pageIndex()}
                <MessageBoxModal
                    visible={this.state.show_modal}
                    messagebox={this.state.error_message}
                    toggle={this.toggle}
                />
            </Page>
        );
    }
}
function mapStateToProps(state) {
    const currentUser = loginSelectors.getUser(state);
    return {
      user : currentUser
    };
  } 
  export default connect(mapStateToProps)(RechargePage)