import LocalizedStrings from 'react-localization';

const Strings = new LocalizedStrings({
 pt_br:{
   yes: "Sim",
   no: "Não",
   tag: "TAG",
   email: "E-mail",
   email_error: "E-mail inválido",
   password: "Senha",
   password_error: "O password deve conter letras, números e pelo menos 6 caracteres",
   success_recovery_pwd: "Enviamos um e-mail com uma nova senha para acessar o sistema.",
   password_changed: "Sua senha foi alterada com sucesso!",
   login_title: "Faça o login para acessar o sistema",
   submit: "Enviar",
   forgot_password: "Esqueci minha senha",
   goback: "Voltar",
   forgot_pwd_title: "Digite seu e-mail para recuperar sua senha",
   cancel: "Cancelar",
   edit: "Editar",
   delete: "Remover",
   close: "Fechar",
   next: "Avançar",
   finish: "Finalizar",
   actions: "Ações",
   nothing_show: "Nenhum resultado encontrado",
   loading: "Carregando",
   detail_button: "Ver detalhes",
   description: "Descrição:",
   required_field : "Campo obrigatório",
   rules: "Regras",
   confirm_delete: "Deseja realmente deletar esse registro?",
   state: "Estado",
   status: "Status",
   spot_type: "Tipo",
   city: "Cidade",
   document: "CPF/CNPJ",
   plate: "Placa",
   confirm_plate: "Confirmar Placa",
   cel_phone: "Celular",
   vehicle_type: "Tipo do Veículo",
   title_pag_success_ticket: "PLACA DO VEÍCULO",
   spot_type_pag_success_ticket: "ZONA",
   period_pag_success_ticket: "PERÍODO",
   day_pag_success_ticket: "DIA",
   time_start_pag_success_ticket: "HORA INICIAL",
   time_stop_pag_success_ticket: "HORA FINAL",
   values_pag_success_ticket: "Valor: R$",
 },
 en:{
    email: "Email",
    email_error: "Invalid email",
    password: "Password",
    password_error: "Your password must have one letter, a number and at least six characters",
    login_title: "Sign in to access the system",
    submit: "Send",
    forgot_password: "Forgot password",
    goback: "Back",
    operator_view_title: "Convocar Inspetor",
    vehicle_type: "Vehicle Type",
  }
});

export default Strings;
