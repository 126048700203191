import logoViaPlus from 'assets/img/logo/logo_via_plus.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, FormFeedback, Input, Label, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import * as loginSelectors from '../store/login/reducer';
import * as loginActions from '../store/login/actions';
import Loader from './Loader';

function getTokenUrl() {
  var token = window.location.pathname.split("/");
  if(token[2]){
    return token[2];
  }
  return false;
}
function sendUrl() {
  var url = window.location.pathname.split("/");
  if(url[1]==='change-password'){
    window.location.href = 'http://viamaisestacionamento.com.br/';
  } else {
    window.location.href = 'login';
  }
  return;
}

class AuthForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      token: getTokenUrl(),
      email: '',
      emailInvalid: false,
      password: '',
      passwordInvalid: false,
      repeat_password: '',
      repeatPasswordInvalid: false,
      loading: false,
      return: '',
      message: '',
      color_message: '',
      required_password: 'O campo senha deve ser preenchido de acordo com a regra a seguir. Deve conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
      required_repeat_password: 'O campo deve ser igual ao campo senha, ambos devem conter: letras maiúsculas, letras minúsculas, números e no mínimo 7 caracteres',
      required_email: 'Campo de e-mail é obrigatório e deve ser preenchido corretamente.',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.callBackMessage = this.callBackMessage.bind(this);
    this.doRemember = this.doRemember.bind(this);
    this.doChange = this.doChange.bind(this);
    this.doLogin = this.doLogin.bind(this);
  }
  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
    if(authState === 'LOGIN'){
      this.doLogin();
    }
  };
  callBackMessage(value){
    this.setState({
      loading: true,
      message: (value.return.code===1?value.data.message:value.return.message),
      color_message: (value.return.code===1?'success':''),
    });
  }
  callBackErrorLogin(message){
    this.setState({message: message});
  }
  handleSubmit = event => {
    event.preventDefault();
  }
  handleInputChange (event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  componentWillUnmount(){
    this.setState({loading: false});
    loginSelectors.cleanState();
  }
  doChange(){
    var invalid = false;
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({passwordInvalid: true});
    } else {
      this.setState({passwordInvalid: false});
    }
    if (this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({repeatPasswordInvalid: true});
    } else {
      this.setState({repeatPasswordInvalid: false});
    }
    if (this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/)!==null && this.state.repeat_password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/)!==null){
      if (this.state.password.trim()!==this.state.repeat_password.trim()) {
        invalid = true;
        this.setState({passwordInvalid: true});
        this.setState({repeatPasswordInvalid: true});
      } else {
        this.setState({passwordInvalid: false});
        this.setState({repeatPasswordInvalid: false});
      }
    }

    // Check Valid
    if(invalid===true){
      return false;
    } else {
      // Success
      this.setState({loading : true});
      this.props.dispatch(
        loginActions.changePassword(
          this.state.token,
          this.state.password,
          this.state.repeat_password,
          this.callBackMessage
        )
      );
    }
  }
  doRemember(){
    if(this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
      this.setState({emailInvalid: true});
      return false;
    } else {
      this.setState({emailInvalid: false});
    }
    this.setState({loading: true});
    this.props.dispatch(
      loginActions.rememberPassword(
        this.state.email,
        this.callBackMessage
      )
    );
  }
  doLogin(){
    var invalid = false;
    if(this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null) {
      invalid = true;
      this.setState({emailInvalid: true});
    } else {
      this.setState({emailInvalid: false});
    }
    if(this.state.password.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{7,}$/) === null) {
      invalid = true;
      this.setState({passwordInvalid: true});
    } else {
      this.setState({passwordInvalid: false});
    }
    if(invalid===true){
      return false;
    }
    this.setState({loading: true, message : ""});
    this.props.dispatch(loginActions.do_login(this.state.email, this.state.password, this.callBackErrorLogin.bind(this)));
  }
  render() {
    const {
      showLogo,
      usernameInputProps,
      passwordInputProps,
      repeatPasswordInputProps,
      children,
      onLogoClick,
      authState,
    } = this.props;

    let ShowFormGroup;
    switch(authState){
      default:
      case 'LOGIN':
        ShowFormGroup = (
          <div>
            <Label style={{textAlign: 'center', width: '100%'}}>Faça o login para ter acesso ao sistema.</Label>
            <FormGroup>
              <Input
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                invalid={this.state.emailInvalid}
                {...usernameInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{__html: this.state.required_email}}></FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                invalid={this.state.passwordInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doLogin(); }}}
                {...passwordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{__html: this.state.required_password}}></FormFeedback>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" />{' '}Lembrar senha
              </Label>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doLogin}
            >Login</Button>
            <div className="text-center pt-1">
              <br />
              <h6>
                <a href="#rememberpassword" onClick={this.changeAuthState(STATE_RMPW)}>Esqueci a Senha</a>
              </h6>
            </div>
            {children}
          </div>
        );
      break;
      case 'RMPW':
        ShowFormGroup = (
          <div>
            <Label style={{textAlign: 'center', width: '100%'}}>Digite seu e-mail para recuperar a senha.</Label>
            <FormGroup>
              <Input
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                invalid={this.state.emailInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doRemember(); }}}
                {...usernameInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{__html: this.state.required_email}}></FormFeedback>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doRemember}
            >Recuperar Senha</Button>
            <div className="text-center pt-1">
              <br />
              <h6>
                <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>Fazer Login</a>
              </h6>
            </div>
            {children}
          </div>
        );
      break;
      case 'CGPW':
        ShowFormGroup = (
          <div>
            <Label style={{textAlign: 'center', width: '100%'}}>Digite a nova senha do seu login.</Label>
            <FormGroup>
              <Input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                invalid={this.state.passwordInvalid}
                {...passwordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{__html: this.state.required_password}}></FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                name="repeat_password"
                type="password"
                value={this.state.repeat_password}
                onChange={this.handleInputChange}
                invalid={this.state.repeatPasswordInvalid}
                onKeyPress={(ev) => { if (ev.key === 'Enter') { this.doChange(); }}}
                {...repeatPasswordInputProps}
              />
              <FormFeedback dangerouslySetInnerHTML={{__html: this.state.required_repeat_password}}></FormFeedback>
            </FormGroup>
            <br />
            <Button
              outline
              size="lg"
              className=""
              block
              onClick={this.doChange}
            >Alterar Senha</Button>
            {children}
          </div>
        );
      break;
    }
    return (
      <div>
        <Form onSubmit={this.handleSubmit} hidden={this.state.loading}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logoViaPlus}
                className="rounded"
                style={{ width: 190, height: 60, cursor: 'pointer' }}
                alt="Eco Parking"
                onClick={onLogoClick}
              />
            </div>
          )}
          {ShowFormGroup}
        </Form>
        <Loader
          body={ModalBody}
          hidden={!this.state.loading}
          message={this.state.message}
          color_message={this.state.color_message}
          onClose={() => this.setState({loading: false, message: '', color_message: ''})}
          onCloseSuccess={() => sendUrl()}
        />
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_RMPW = 'RMPW';
export const STATE_CGPW = 'CGPW';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_RMPW, STATE_CGPW]).isRequired,
  showLogo: PropTypes.bool,
  usernameInputProps: PropTypes.object,
  passwordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};
AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameInputProps: {
    type: 'email',
    placeholder: 'email@viaparking.com',
  },
  passwordInputProps: {
    type: 'password',
    placeholder: 'Senha',
  },
  repeatPasswordInputProps: {
    type: 'password',
    placeholder: 'Repita a Senha',
  },
  onLogoClick: () => {},
};

// which props do we want to inject, given the global store state?
function mapStateToProps(state) {
  return {
    user : loginSelectors.getUser(state),
    error : loginSelectors.getErrorLogin(state)
  };
}
export default connect(mapStateToProps)(AuthForm);