import * as React from 'react';
import Page from 'components/Page';
import {
Row,
Col,
Form,
FormGroup,
Input,
Button,
} from 'reactstrap';

// Components
import CreditCard from 'components/CreditCard';
import DeleteModal from 'components/DeleteModal';
import ChangePassword from 'components/ChangePassword';
import CreditCardCreate from 'components/CreditCardCreate';

// Reducer & Action
import { connect } from 'react-redux';
import * as loginSelectors from 'store/login/reducer';

// Connection
import BaseService from '../services/BaseService';

class ContaPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      nome: '',
      email: '',
      methodt: '',
      credit_form: true,
      show_form_password: false,
      show_form_credit_card: false,
      show_deleting : false,
      show_button_deleting : true,
      editingObj : null,
      delete_id : -1,
      reload: false,
    };
    this.closed = this.closed.bind(this);
    this.addActionPassword = this.addActionPassword.bind(this);
    this.addActionCreditCard = this.addActionCreditCard.bind(this);
    this.closedDelete = this.closedDelete.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.getDataUser = this.getDataUser.bind(this);
  }
  componentDidMount(){
    this.getDataUser();
  }
  addActionPassword(){
    this.setState({
      show_form_password: true
    });
  }
  addActionCreditCard(){
    this.setState({
      show_form_credit_card: !this.state.show_form_credit_card
    });
  }
  closed(refresh){
    if(refresh){
      this.setState({
        show_form_password: false,
        show_form_credit_card: false,
        reload: !this.state.reload,
        editingObj: null
      });
    }else{
      this.setState({
        show_form_password: false,
        show_form_credit_card: false,
        editingObj: null
      });
    }
  }
  closedDelete(refresh){
    if(refresh){
      this.setState({ show_deleting: false, reload: !this.state.reload, delete_id: -1} );
    }else{
      this.setState({ show_deleting: false , delete_id: -1});
    }
  }
  deleteAction(idx, obj){
    this.setState({ show_deleting: true , delete_id: obj.id});
  }
  getDataUser(){
    const url = BaseService.ENDPOINT + 'access-point/user';
    return fetch(url, {
      method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + this.props.user.access_token,
        })
      })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
          nome: json.data.name,
          email: json.data.email,
        })
      }else if(json.return.code === -403){
        window.location = '/';
      }
    });
  }
  render() {
    return (
      <Page
        title="Dados da Conta"
        className="conta-page">
         <Row>
          <Col md="4" sm="0" xs="0"></Col>
          <Col md="4" sm="12" xs="12">
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  value={this.state.nome}
                  disabled
                  >
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  value={this.state.email}
                  disabled
                  >
                </Input>
              </FormGroup>
              <FormGroup>
                <span className="label-right">
                  <a href="#change-password" onClick={this.addActionPassword} alt="Mudar Senha">Mudar Senha</a>
                </span>
              </FormGroup>
            </Form>
          </Col>
          <Col md="4" sm="0" xs="0"></Col>
        </Row>
        <Row>
          <Col md="12" sm="12" xs="12">
            <label className="sub-title">CARTÕES CADASTRADOS</label>
          </Col>
        </Row>
        <Row>
          <Col md="12" sm="12" xs="12" className="box-button-store-credit-card">
            <Button className="button-store-credit-card" onClick={this.addActionCreditCard}>Cadastrar Novo</Button>
          </Col>
        </Row>
        <Row className="marginBottom100">
          <Col md="2" sm="0" xs="0"></Col>
          <Col md="8" sm="12" xs="12">
            <CreditCard
              showDeleting={this.state.show_button_deleting}
              user={this.props.user}
              hidden={this.state.credit_form}
              deleteAction={this.deleteAction}
              methodtInvalid={this.state.methodtInvalid}
              methodSelected={(credit) => this.setState({methodt: credit})}
              reload={this.state.reload}
            />
          </Col>
          <Col md="2" sm="0" xs="0"></Col>
        </Row>
        <ChangePassword
          title="Trocar Senha"
          visible={this.state.show_form_password}
          user={this.props.user}
          toggle={this.closed}
        />
        <CreditCardCreate
          api="access-point/credit-card"
          title="Adicionar Cartão de Crédito"
          visible={this.state.show_form_credit_card}
          user={this.props.user}
          reload={this.state.reload}
          toggle={this.closed}
        />
        <DeleteModal
          visible={this.state.show_deleting}
          user={this.props.user}
          toggle={this.closedDelete}
          api="access-point/credit-card/"
          value={this.state.delete_id}
        />
      </Page>
    );
  }
};
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
} 
export default connect(mapStateToProps)(ContaPage)