import React from 'react';
import { STATE_LOGIN, STATE_RMPW, STATE_CGPW } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';

// Pages
import AuthPage from 'pages/AuthPage';
import ContaPage from 'pages/ContaPage';
import ComprarPage from 'pages/ComprarPage';
import VenderPage from 'pages/VenderPage';
import HistoricoPage from 'pages/HistoricoPage';

// Style Page
import './styles/reduction.css';

// Reduce & Action
import * as loginSelectors from 'store/login/reducer';
import * as loginActions from 'store/login/actions';
import { connect } from 'react-redux';
import RechargePage from './pages/RechargePage';
import VendaDiretaPage from './pages/VendaDiretaPage';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};
class MainApp extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      user: undefined
    };
    this.props.dispatch(loginActions.getUserStorage());
  }
  render(){
    if(this.props.user !== undefined && this.props.user){
      return (
        <App user={this.props.user}/>
      )
    }else{
      return (
        <BrowserRouter>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )
                }
              />
              <LayoutRoute
                exact
                path="/remember-password"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_RMPW} />
                  )
                }
              />
              <LayoutRoute
                exact
                path="/change-password/:token"
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_CGPW} />
                  )
                }
              />
              <LayoutRoute
                layout={EmptyLayout}
                component={
                  props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )
                }
              />
            </Switch>
          </GAListener>
        </BrowserRouter>
      )
    }
  }
}
class App extends React.Component{
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={MainLayout}
              component={ContaPage}
            />
            <LayoutRoute
              exact
              path="/conta"
              layout={MainLayout}
              component={ContaPage}
            />
            <LayoutRoute
              exact
              path="/comprar"
              layout={MainLayout}
              component={ComprarPage}
            />
            <LayoutRoute
              exact
              path="/vender"
              layout={MainLayout}
              component={VenderPage}
            />
            <LayoutRoute
              exact
              path="/venda-direta"
              layout={MainLayout}
              component={VendaDiretaPage}
            />
            <LayoutRoute
              exact
              path="/historico"
              layout={MainLayout}
              component={HistoricoPage}
            />
            <LayoutRoute
              exact
              path="/recarga"
              layout={MainLayout}
              component={RechargePage}
            />
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}
const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }
  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }
  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }
  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }
  if (width > 1200) {
    return { breakpoint: 'xl' };
  }
  return { breakpoint: 'xs' };
};
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
}
export default connect(mapStateToProps)(componentQueries(query)(MainApp));