import * as types from './actionTypes';
import LoginService from '../../services/LoginService';

//metodo chamado na view
export function do_login(email, password, callbackError) {
  return async(dispatch, getState) => {
    try {
      let userLogged = JSON.parse(localStorage.getItem("user"));
      if(!userLogged || userLogged.access_token === ""){
        userLogged = await LoginService.do_login(email, password, callbackError);
        localStorage.setItem('user', JSON.stringify(userLogged));
      }
      if(userLogged===null){
        return;
      }
      dispatch({type: types.USER_LOGGED, user: userLogged, error: null});
    } catch (error) {
      console.error(error);
      dispatch({type: types.USER_LOGGED, user: null, error: error.message});
    }
  };
}

//metodo chamado na view
export function rememberPassword(email, callback) {
  return async(dispatch, getState) => {
    try {
      let userRemember = await LoginService.rememberPassword(email, callback);
      if(userRemember===null){
        return;
      }
    } catch (error) {
      dispatch({type: types.USER_REMEMBER, user: undefined, error: error.message});
    }
  };
}

export function changePassword(token, password, repeat_password, callback) {
  return async(dispatch, getState) => {
    try {
      let userChangepassword = await LoginService.changePassword(token, password, repeat_password, callback);
      if(userChangepassword===null){
        return;
      }
    } catch (error) {
      dispatch({type: types.USER_LOGGED, user: undefined, error: error.message});
    }
  };
}

export function getUserStorage(){
  return async(dispatch, getState) => {
    try {
      let userLogged = JSON.parse(localStorage.getItem("user"));
      if(userLogged){
        userLogged = await LoginService.validToken(userLogged.access_token);
        if(userLogged !== null){
          localStorage.setItem('user', JSON.stringify(userLogged));
          dispatch({type: types.USER_LOGGED, user: userLogged, error: ""});
        }else{
          localStorage.removeItem("user");
          dispatch({type: types.USER_LOGGED, user: undefined, error: ""});
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function logout(){
  return async(dispatch, getState) => {
    try {
      localStorage.removeItem("user");
      dispatch({type: types.USER_LOGGED, user: undefined, error: ""});
    } catch (error) {
      console.error(error);
    }
  };
}
