import * as React from 'react';
import * as PropTypes from 'prop-types';
import BaseService from '../services/BaseService';
import { MdCreate, MdClear} from 'react-icons/lib/md';
import {
  Button,
  ButtonGroup,
  Row,
  Col,
} from 'reactstrap';
import {
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Plugin,
  Template,
  TemplateConnector,
  Getter,
} from '@devexpress/dx-react-core';

const FilterIcon = ({ type }) => {
  if (type === 'month') {
    return (
      <span
        className='far fa-calendar'
      />
    );
  }
  return <TableFilterRow.Icon type={type} />;
};
const CurrencyEditor = ({ value, onValueChange }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === '') {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };
  return (
    <input
      className="form-control text-right"
      type="number"
      placeholder="Filtro"
      value={value === undefined ? '' : value}
      min={0}
      onChange={handleChange}
    />
  );
};
CurrencyEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};
CurrencyEditor.defaultProps = {
  value: undefined,
};

export default class DynamicTable extends React.PureComponent{
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      rows: [],
      filters: [],
      pageSizes: [10, 15, 20, 50, 100, 0],
      totalCount: 0,
      pageSize: 6,
      currentPage: 0,
      loading: true,
      dateColumns: [],
      currencyColumns: [],
      dateFilterOperations: ['month', 'contains', 'startsWith', 'endsWith'],
      currencyFilterOperations: ['equal', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual'],
      filteringColumnExtensions: [{
        columnName: 'saleDate',
        predicate: (value, filter, row) => {
          if (!filter.value.length) return true;
          if (filter && filter.operation === 'month') {
            const month = parseInt(value.split('-')[1], 10);
            return month === parseInt(filter.value, 10);
          }
          return IntegratedFiltering.defaultPredicate(value, filter, row);
        },
      }],
    };
    this.getUsers = this.getUsers.bind(this);
    this.changeFilters = filters => this.setState({ filters });
    this.CustomTableEditColumn = () => (
      <Plugin>
        <Getter
          name="tableColumns"
          computed={({ tableColumns }) => {
            const result = tableColumns.slice();
            result.push({ key: 'delete', type: 'delete', width: 130 });
            return result;
          }}
        />
        <Template
          name="tableCell"
          predicate={({ tableColumn, tableRow }) => tableColumn.type === 'delete' && tableRow.type === 'data'} >
          {params => (
            <TemplateConnector>
              {(getters, { deleteRows, commitDeletedRows }) => (
                <td>
                  <ButtonGroup className="mr-2 mb-2">
                    <Button
                      style={{ marginRight: '8px' }}
                      outline
                      color="primary"
                      onClick={() => {
                        const rowIds = [params.tableRow.rowId];
                        this.props.editAction(rowIds, this.state.rows[rowIds])
                      }} ><MdCreate size={25} /></Button>
                    <Button
                      outline
                      color="danger"
                      onClick={() => {
                        const rowIds = [params.tableRow.rowId];
                        this.props.deleteAction(rowIds, this.state.rows[rowIds])
                      }} ><MdClear size={25} /></Button>
                  </ButtonGroup>
                </td>
              )}
            </TemplateConnector>
          )}
        </Template>
      </Plugin>
    );
  }
  componentDidMount(){
    this.getUsers();
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.reload !== this.props.reload) {
      this.getUsers();
    }
  }
  getUsers() {
    const url = BaseService.ENDPOINT + this.props.api;
    fetch(url,  {
      method: 'GET',
      headers: new Headers({
       Accept: 'application/json',
        Authorization: 'Bearer ' + this.props.user.access_token
      })
    })
    .then(results => {
      return results.json();
    })
    .then(data => {
      if(data.return.code === 1){
        this.setState({ rows : data.data });
      }else if(data.return.code === '-403'){
        window.location = '/';
      }
    })
  }
  render() {
    const {
      rows,
      pageSizes,
      filters,
      dateColumns,
      dateFilterOperations,
      filteringColumnExtensions,
      currencyColumns,
      currencyFilterOperations,
      columns = (this.props.columns === undefined ? [] : this.props.columns)
    } = this.state;
    // const CustomTableEditColumn = this.CustomTableEditColumn;
    return (
      <Row>
        <Col md="1" sm="0" xs="0"></Col>
        <Col md="10" sm="12" xs="12">
            <Grid rows={rows} columns={columns}>
              <DataTypeProvider
                for={dateColumns}
                availableFilterOperations={dateFilterOperations}
              />
              <DataTypeProvider
                for={currencyColumns}
                availableFilterOperations={currencyFilterOperations}
                editorComponent={CurrencyEditor}
              />
              <FilteringState
                filters={filters}
                onFiltersChange={this.changeFilters}
                hidden={this.props.filterState}
              />
              <IntegratedFiltering
                columnExtensions={filteringColumnExtensions}
                hidden={this.props.integratedFilter}
              />
              <PagingState
                defaultCurrentPage={0}
                defaultPageSize={10}
                hidden={this.props.showPagingState}
              />
              <IntegratedPaging 
                hidden={this.props.integratePag}
              />
              <Table/>
              <TableHeaderRow />
              {/* <CustomTableEditColumn /> */}
              <TableFilterRow
                hidden={this.props.dateFilter}
                showFilterSelector
                iconComponent={FilterIcon}
                messages={{ month: 'Month equals' }}
              />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
        </Col>
        <Col md="1" sm="0" xs="0"></Col>
      </Row>
    );
  }
};