import React from 'react';
import Page from 'components/Page';
import DynamicTable from 'components/DynamicTable';
import { connect } from 'react-redux';
import * as loginSelectors from 'store/login/reducer';

class HistoricoPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      show_form: false,
      show_deleting : false,
      columns: [
        {name: 'id', title: 'Número do Pedido', getCellValue: row => ('#'+row.id)},
        {name: 'date', title: 'Data do registro'},
        {name: 'period', title: 'Período', getCellValue: row => (row.period+' Horas')},
        {name: 'value', title: 'Valor', getCellValue: row => ('R$ ' + row.value+',00')},
        {name: 'time', title: 'Hora'},
      ],
      reload : false,
      editingObj : null,
      delete_id : -1
    };
  }
  render() {
    return (
      <Page
        title="Histórico"
        className="HistoricoPage  marginBottom100">
        <DynamicTable
          user={this.props.user}
          api="access-point/history"
          columns={this.state.columns}
          dateFilter="false"
          integratedFilter="false"
          currencyColumns="false"
          showPagingState="false"
          filterState="false"
          integratePag="false"
          deleteAction={this.deleteAction}
          editAction={this.editAction}
          reload={this.state.reload}
        />
      </Page>
    );
  }
};
function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
}
export default connect(mapStateToProps)(HistoricoPage)