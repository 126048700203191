import IconLixo from 'assets/img/icones/icon-lixo.png';
import LogoAmex from 'components/LogoAmex';
import LogoDefault from 'components/LogoDefault';
import LogoDiners from 'components/LogoDiners';
import LogoElo from 'components/LogoElo';
import LogoHiper from 'components/LogoHiper';
import LogoHiperCard from 'components/LogoHiperCard';
import LogoMaster from 'components/LogoMaster';
import LogoVisa from 'components/LogoVisa';
import React from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import BaseService from '../services/BaseService';
import _ from 'lodash';

class CreditCard extends React.Component{
  constructor(props) {
    super(props);
  	this.state = ({
      data_card:  {},
  		selected_card:  '',
  	});
    this.getCards = this.getCards.bind(this);
    this.setFilter = this.setFilter.bind(this);
  	this.isActive = this.isActive.bind(this);
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.reload !== this.props.reload) {
      this.getCards();
      this.setState({
        selected_card:  '',
      });
    }
  }
  componentDidMount(){
    this.getCards();
  }
  setFilter(obj) {
    this.setState({
      selected_card: obj.id,
    });
    this.props.methodSelected(obj);
  }
  isActive(obj_id){
    return ' credit-card '+((obj_id===this.state.selected_card) ?'selected':'');
  }
  getCards(){
    const url = BaseService.ENDPOINT + 'access-point/credit-card';
    return fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization' : 'Bearer ' + this.props.user.access_token,
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
        	data_card: json.data
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      }
    });
  }
  render() {
    let LogoCreditCard = '';
    let DivCreditCard = [];
    let styleClassName = 'card-secundary';
    let styleClassNameInvalid = '';
    if(this.state.data_card){
      _.map(this.state.data_card, (obj) => {
        styleClassName = (styleClassName === 'card-primary' ? 'card-secundary' : 'card-primary');
        if(this.props.methodInvalid===true){
          styleClassNameInvalid = ' card-invalid';
        }
        switch(obj.card_flag){
        	case 'AMEX':
        		LogoCreditCard = <LogoAmex />;
        	break;
        	case 'DINERS':
        		LogoCreditCard = <LogoDiners />;
        	break;
        	case 'ELO':
        		LogoCreditCard = <LogoElo />;
        	break;
        	case 'HIPER':
        		LogoCreditCard = <LogoHiper />;
        	break;
        	case 'HIPERCARD':
        		LogoCreditCard = <LogoHiperCard />;
        	break;
        	case 'MASTERCARD':
        		LogoCreditCard = <LogoMaster />;
        	break;
        	case 'VISA':
        		LogoCreditCard = <LogoVisa />;
        	break;
        	default:
        		LogoCreditCard = <LogoDefault />;
        	break;
        }
        DivCreditCard.push(
          <Col md="6" sm="12" xs="12" key={obj.id} style={{marginTop: '20px', marginBottom: '20px' }}>
            <div className={styleClassName+this.isActive(obj.id)+styleClassNameInvalid} onClick={this.setFilter.bind(this, obj)}>
              <Row>
                <Col>
                  <label>Nome</label>
                  <label className="label-input">{obj.name}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Número do Cartão</label>
                  <label className="label-input">XXXX XXXX XXXX {obj.cc_final}</label>
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="4" xs="4">
                  <label>Venc</label>
                  <label className="label-input">{obj.expiration}</label>
                </Col>
                <Col md="3" sm="3" xs="3">
                  <label>CVC</label>
                  <label className="label-input">XXX</label>
                </Col>
                <Col md="5" sm="5" xs="5">
                  {LogoCreditCard}
                </Col>
              </Row>
              <span
                hidden={!this.props.showDeleting}
                className="delete_card"
                onClick={ () => {this.props.deleteAction(obj.id, obj)}}>
                <img className="icon-garbage" alt="Excluir Cartão" src={IconLixo} />
              </span>
            </div>
          </Col>
        );
      });
    } else {
      DivCreditCard = <p style={{textAlign: 'center', width: '100%'}}>Nenhum cartão cadastrado</p>
    }
    return (
      <Row className="box-selection-credit-card" hidden={!this.props.hidden}>
        {DivCreditCard}
      </Row>
    )
  }
}
export default CreditCard;