import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';

class MessageBoxModal extends React.Component{
  constructor(props){
    super(props)
    this.toggle = this.toggle.bind(this);
  }
  toggle(refresh){
    this.props.toggle(refresh);
  }
  render() {
    return (
      <Modal isOpen={this.props.visible} >
        <ModalBody >{this.props.messagebox}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle(false)}>
               OK
            </Button>
        </ModalFooter>
    </Modal>
    )
  }
}

export default MessageBoxModal;