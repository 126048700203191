import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import SelectAPI from './SelectAPI';
import Strings from '../strings/Strings';
import BaseService from '../services/BaseService';
import Loader from './Loader';
import CustomInput from './CustomInput';

// Moip
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js';
import JSEncrypt from 'node-jsencrypt';

const pubKey = `-----BEGIN PUBLIC KEY-----
                MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvnzpUjHi59KbQOXDaGL3
                oTFTRvHQyusXC5J2BIPDBBbRsJqbhI4Wk5XWP9w6FrviyniMfcOJu6+l45AXHHd1
                7wOu+H9pFPCajP8v1SUO3grAXPuPnBv1feHJAm0aE2iA5GI26IfjbW2piq1VC/M1
                lXF9go03jENJg/GQGk4PZgeDkl8KEvPTglmiIc87zRwLat1UGXD3d4uSlQ+Tvkh5
                RvzVOaLy5dq2hgW9LH6d/GVnJg1AMl1KLD8Rjk+kO/d2WUHHupwUQCjJd1jrq4eX
                4pxVHjMTqHOfbps1gY27Tbghlhl8ibi7y8lkNl2kjPQIc32G8Z1NSbuQtyHh7UH/
                +wIDAQAB
                -----END PUBLIC KEY-----`;

class CreditCardCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      nameInvalid: false,
      birthdate: '',
      birthdateInvalid: false,
      document: '',
      documentInvalid: false,
      street: '',
      streetInvalid: false,
      street_number: '',
      streetNumberInvalid: false,
      complement: '',
      complementInvalid: false,
      // district: '',
      // districtInvalid: false,
      // city: null,
      // cityInvalid: false,
      // state: null,
      // stateInvalid: false,
      zip_code: '',
      zipCodeInvalid: false,
      card_flag: '',
      card_number: '',
      cardNumberInvalid: false,
      expiration: '',
      expirationInvalid: false,
      cvc: '',
      cvcInvalid: false,
      card_hash: '',
      loading: false,
      message: '',
      color_message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.valid_field = this.valid_field.bind(this);
    this.sleep = this.sleep.bind(this);
    this.register = this.register.bind(this);
  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = (target.type === 'checkbox' ? target.checked : (target.name !== 'name' ? target.value : target.value.toUpperCase()));
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  toggle(refresh) {
    this.setState({
      name: '',
      nameInvalid: false,
      birthdate: '',
      birthdateInvalid: false,
      document: '',
      documentInvalid: false,
      street: '',
      streetInvalid: false,
      street_number: '',
      streetNumberInvalid: false,
      complement: '',
      complementInvalid: false,
      // district: '',
      // districtInvalid: false,
      // city: null,
      // cityInvalid: false,
      // state: null,
      // stateInvalid: false,
      zip_code: '',
      zipCodeInvalid: false,
      card_flag: '',
      card_number: '',
      cardNumberInvalid: false,
      expiration: '',
      expirationInvalid: false,
      cvc: '',
      cvcInvalid: false,
      card_hash: '',
      loading: false,
      message: '',
      color_message: '',
    });
    this.props.toggle(refresh);
  }
  validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14)
      return false

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj))
      return false

    // Cáculo de validação
    let t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0), 10),
      d2 = parseInt(d.charAt(1), 10),
      calc = x => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0

        for (let i = x; i >= 1; i--) {
          s += n.charAt(x - i) * y--;
          if (y < 2)
            y = 9
        }

        r = 11 - s % 11
        return r > 9 ? 0 : r
      }

    return calc(t) === d1 && calc(t + 1) === d2
  }
  validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    if (cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999")
      return false;
    var add = 0;
    for (var i = 0; i < 9; i++)
      add += parseInt(cpf.charAt(i), 10) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
      rev = 0;
    if (rev !== parseInt(cpf.charAt(9), 10))
      return false;
    add = 0;
    for (i = 0; i < 10; i++)
      add += parseInt(cpf.charAt(i), 10) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
      rev = 0;
    if (rev !== parseInt(cpf.charAt(10), 10))
      return false;
    return true;
  }
  valid_field() {
    var invalid = false;
    if (this.state.name.length < 1) {
      this.setState({ nameInvalid: true });
      invalid = true;
    } else {
      this.setState({ nameInvalid: false });
    }
    if (this.state.birthdate.length !== 10) {
      this.setState({ birthdateInvalid: true });
      invalid = true;
    } else {
      this.setState({ birthdateInvalid: false });
    } if (this.state.document.length === 14) {
      if (this.validarCPF(this.state.document) === true) {
        this.setState({ documentInvalid: false })
      }
      else {
        invalid = true;
        this.setState({ documentInvalid: true })
      }
    }
    else {
      if (this.validarCNPJ(this.state.document) === true) {
        this.setState({ documentInvalid: false })
      }
      else {
        invalid = true;
        this.setState({ documentInvalid: true })
      }
    }
    if (this.state.street.length <= 6) {
      this.setState({ streetInvalid: true });
      invalid = true;
    } else {
      this.setState({ streetInvalid: false });
    }
    if (this.state.street_number === '') {
      this.setState({ streetNumberInvalid: true });
      invalid = true;
    } else {
      this.setState({ streetNumberInvalid: false });
    }
    // if (this.state.district === '') {
    //   this.setState({ districtInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ districtInvalid: false });
    // }
    // if (this.state.city === null || this.state.city.length <= 0) {
    //   this.setState({ cityInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ cityInvalid: false });
    // }
    // if (this.state.state === null || this.state.state.length <= 0) {
    //   this.setState({ stateInvalid: true });
    //   invalid = true;
    // } else {
    //   this.setState({ stateInvalid: false });
    // }
    if (this.state.zip_code.length < 8) {
      this.setState({ zipCodeInvalid: true });
      invalid = true;
    } else {
      this.setState({ zipCodeInvalid: false });
    }
    if (this.state.card_number.length < 17) {
      this.setState({ cardNumberInvalid: true });
      invalid = true;
    } else {
      this.setState({ cardNumberInvalid: false });
    }
    if (this.state.expiration.length !== 7) {
      this.setState({ expirationInvalid: true });
      invalid = true;
    } else {
      this.setState({ expirationInvalid: false });
    }
    if (this.state.cvc.length < 3) {
      this.setState({ cvcInvalid: true });
      invalid = true;
    } else {
      this.setState({ cvcInvalid: false });
    }

    // Check Validations
    if (invalid === true) {
      return false;
    }

    // Success
    return true;
  }
  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
  register() {
    if (this.valid_field() === false) {
      return false;
    }

    var dateExpiration = this.state.expiration;
    dateExpiration = dateExpiration.split("/");

    // var cardHash;
    // MoipCreditCard
    //   .setEncrypter(JSEncrypt, 'node')
    //   .setPubKey(pubKey)
    //   .setCreditCard({
    //     number: this.state.card_number,
    //     cvc: this.state.cvc,
    //     expirationMonth: dateExpiration['0'],
    //     expirationYear: dateExpiration['1']
    //   })
    //   .hash()
    //   .then((hash) => {
    //     cardHash = hash;
    //   });

    this.sleep(1000).then(() => {
      var documentType = 'CNPJ';
      if (this.state.document.length >= 11 && this.state.document.length <= 14) {
        documentType = 'CPF';
      }

      console.log(documentType);

      var cardFlag = MoipValidator.cardType(this.state.card_number);
      // console.log('cardFlag: ', cardFlag);
      if (cardFlag !== null) {
        cardFlag = cardFlag['brand'];
      } else {
        cardFlag = 'NULL';
      }

      var cardNumber = this.state.card_number;
      cardNumber = cardNumber.replace(' ', '').replace(' ', '').replace(' ', '');
      // cardNumber = cardNumber.substring((cardNumber.length), (cardNumber.length - 4));

      this.setState({ loading: true });
      let url = BaseService.ENDPOINT + 'access-point/credit-card';
      var form = new FormData();
      form.append("name", this.state.name);
      form.append("birthdate", this.state.birthdate);
      form.append("document", this.state.document);
      form.append("document_type", documentType);
      form.append("street", this.state.street);
      form.append("street_number", this.state.street_number);
      form.append("complement", this.state.complement);
      // form.append("district", this.state.district);
      // form.append("city", this.state.city.id);
      // form.append("state", this.state.state.id);
      form.append("zip_code", this.state.zip_code);
      form.append("card_number", cardNumber);
      form.append("card_flag", cardFlag);
      form.append("expiration", this.state.expiration);
      form.append("cvc", this.state.cvc);
      // form.append("hash", cardHash);

      return fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.props.user.access_token,
        }),
        body: form
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.return.code === 1) {
            this.setState({
              loading: true,
              message: 'Cartão cadastrado com sucesso!',
              color_message: 'success',
            });
          } else if (json.return.code === '-403') {
            window.location = '/';
          } else {
            this.setState({
              loading: true,
              message: json.return.message
            });
          }
        });
    });
  }
  render() {
    return (
      <Modal isOpen={this.props.visible}>
        <ModalHeader>Novo Cartão de Crédito</ModalHeader>
        <ModalBody hidden={this.state.loading}>
          <Form>
            <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="name"
                placeholder="Nome Completo"
                value={this.state.name}
                onChange={this.handleInputChange}
                invalid={this.state.nameInvalid}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup>
            <CustomInput
              invalid={this.state.birthdateInvalid}
              mask={"00/00/0000"}
              name="birthdate"
              placeholder='Data de Nascimento'
              value={this.state.birthdate}
              onChange={(value) => { this.setState({ birthdate: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <CustomInput
              invalid={this.state.documentInvalid}
              mask={this.state.document.length > 14 ? "00.000.000/0000-00" : "000.000.000-00000"}
              name="document"
              placeholder="CPF ou CNPJ"
              value={this.state.document}
              onChange={(value) => { this.setState({ document: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="street"
                placeholder="Endereço"
                value={this.state.street}
                onChange={this.handleInputChange}
                invalid={this.state.streetInvalid}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup>
            <CustomInput
              invalid={this.state.streetNumberInvalid}
              mask={"000.000.000"}
              name="street_number"
              placeholder='Número'
              value={this.state.street_number}
              onChange={(value) => { this.setState({ street_number: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="complement"
                placeholder="Complemento"
                value={this.state.complement}
                onChange={this.handleInputChange}
                invalid={false}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup>
            {/* <SelectAPI
              placeholder={Strings.state}
              name="state"
              access_token={this.props.user.access_token}
              api="access-point/states"
              selectedOption={this.handleInputChange}
              value={this.state.state}
              invalid={this.state.stateInvalid}
              formFeedbackMsg={Strings.required_field}
              multi={false}
            />
            <SelectAPI
              placeholder={Strings.city}
              hidden={!(this.state.state !== '')}
              name="city"
              access_token={this.props.user.access_token}
              api={"access-point/cities/" + (this.state.state !== null ? this.state.state.id : '')}
              selectedOption={this.handleInputChange}
              value={this.state.city}
              invalid={this.state.cityInvalid}
              formFeedbackMsg={Strings.required_field}
              multi={false}
            /> */}
            {/* <FormGroup>
              <Input
                type="text"
                maxLength={100}
                name="district"
                placeholder="Bairro"
                value={this.state.district}
                onChange={this.handleInputChange}
                invalid={this.state.districtInvalid}>
              </Input>
              <FormFeedback>{Strings.required_field}</FormFeedback>
            </FormGroup> */}
            <CustomInput
              invalid={this.state.zipCodeInvalid}
              mask={"00000-000"}
              name="zip_code"
              placeholder='CEP'
              value={this.state.zip_code}
              onChange={(value) => { this.setState({ zip_code: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <CustomInput
              invalid={this.state.cardNumberInvalid}
              mask={"0000 0000 0000 0000"}
              name="card_number"
              placeholder='Número do Cartão'
              value={this.state.card_number}
              onChange={(value) => { this.setState({ card_number: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <CustomInput
              invalid={this.state.expirationInvalid}
              mask={"00/0000"}
              name="expiration"
              placeholder='Data Expirar'
              value={this.state.expiration}
              onChange={(value) => { this.setState({ expiration: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
            <CustomInput
              invalid={this.state.cvcInvalid}
              mask={"0000"}
              name="cvc"
              placeholder='CVC'
              value={this.state.cvc}
              onChange={(value) => { this.setState({ cvc: value }) }}
              unmask={false}
              invalid_message={Strings.required_field}
            />
          </Form>
        </ModalBody>
        <Loader
          body={ModalBody}
          hidden={!this.state.loading}
          message={this.state.message}
          color_message={this.state.color_message}
          onClose={() => this.setState({ loading: false, message: '', color_message: '' })}
          onCloseSuccess={() => { this.toggle(true); this.setState({ loading: false, message: '', color_message: '' }); }}
        />
        <ModalFooter hidden={this.state.loading}>
          <Button color="secondary" onClick={this.register}>Cadastrar</Button>
          <Button color="danger" onClick={() => this.toggle(false)}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default CreditCardCreate;