// import BoxBackgroundWithSeparator from 'assets/img/bg/box-mensagem-sucesso/box-background-with-separator.png';
import BoxBackgroundWithSeparator from 'assets/img/bg/box-mensagem-sucesso/box-background-with-separator-ghost.png';
import * as React from 'react';
import Page from 'components/Page';
import {
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Button,
  Label,
  Row,
  Col,
  Modal,
  ModalBody,
} from 'reactstrap';
import ShowTickets from 'components/ShowTickets';
import SelectAPI from 'components/SelectAPI';
import { connect } from 'react-redux';
import * as loginSelectors from 'store/login/reducer';
import BaseService from '../services/BaseService';
import CustomInput from 'components/CustomInput';
import Strings from '../strings/Strings';
import Loader from 'components/Loader';

class VenderPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      page_name: 'Vender Créditos',
      show_form: true,
      show_success: false,
      credit: 0,
      feedback_plate: '',
      feedback_period: '',
      feedback_date: '',
      feedback_time_start: '',
      feedback_time_stop: '',
      feedback_value: '',
      plate: '',
      plateInvalid: false,
      confirm_plate: '',
      confirmPlateInvalid: false,
      document: '',
      mask_document: '000.000.000-000',
      mask_phone: '(00) 0000-00000',
      documentInvalid: false,
      phone: '',
      phoneInvalid: false,
      email: '',
      emailInvalid: false,
      ticket: null,
      ticketInvalid: false,
      vehicle_type: null,
      vehicleTypeInvalid: false,
      reload: false,
      loading: false,
      message: '',
      color_message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.register = this.register.bind(this);
    this.valid_field = this.valid_field.bind(this);
    this.FormInsert = () => (false);
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.editing !== null && nextProps.editing !== this.props.editing) {
      this.setState({
        page_name: 'Vender Créditos',
        plate: '',
        confirm_plate: '',
        document: '',
        mask_document: '000.000.000-000',
        mask_phone: '(00) 0000-00000',
        phone: '',
        email: '',
        vehicle_type: null,
        ticket: null,
        reload: true,
        feedback_plate: '',
        feedback_period: '',
        feedback_date: '',
        feedback_time_start: '',
        feedback_time_stop: '',
        feedback_value: '',
        loading : false,
      });
    }
  }
  componentDidMount(){
    this.getUser();
  }
  handleInputChange(event) {
    // get target
    const target = event.target;
    // if checkbox or input field
    const value = (target.type==='checkbox'?target.checked:(target.name!=='plate'? (target.name!=='confirm_plate'?target.value:target.value.toUpperCase()) :target.value.toUpperCase()));
    // get tag name
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  toggle(refresh){
    this.setState({
      page_name: 'Vender Créditos',
      show_form: true,
      show_success: false,
      plate: '',
      confirm_plate: '',
      document: '',
      mask_document: '000.000.000-000',
      mask_phone: '(00) 0000-00000',
      phone: '',
      email: '',
      ticket: null,
      vehicle_type: null,
      feedback_plate: '',
      feedback_period: '',
      feedback_date: '',
      feedback_time_start: '',
      feedback_time_stop: '',
      feedback_value: '',
      loading: false,
      visible: false,
      message: '',
      reload: true,
    });
    this.getUser();
  }
  valid_field(){
    var invalid = false;
    if(this.state.plate.length<7) {
      invalid = true;
      this.setState({plateInvalid : true});
    } else {
      this.setState({plateInvalid : false});
    }
    if(this.state.confirm_plate.length<7) {
      invalid = true;
      this.setState({confirmPlateInvalid : true});
    } else {
      this.setState({confirmPlateInvalid : false});
    }
    if (this.state.plate.length>=7 && this.state.confirm_plate.length>=7){
      if (this.state.plate.trim()!==this.state.confirm_plate.trim()) {
        invalid = true;
        this.setState({plateInvalid: true});
        this.setState({confirmPlateInvalid: true});
      } else {
        this.setState({plateInvalid: false});
        this.setState({confirmPlateInvalid: false});
      }
    }
    if(this.state.document.trim() === "" || this.state.document.length<11) {
      invalid = true;
      this.setState({documentInvalid : true});
    } else {
      this.setState({documentInvalid : false});
    }
    if(this.state.phone.trim() === "" || this.state.phone.length<11) {
      invalid = true;
      this.setState({phoneInvalid : true});
    } else {
      this.setState({phoneInvalid : false});
    }
    if(this.state.email.trim() === "" || this.state.email.length<8) {
      invalid = true;
      this.setState({emailInvalid : true});
    } else {
      this.setState({emailInvalid : false});
    }
    if(this.state.vehicle_type === null || this.state.vehicle_type.length<0) {
      invalid = true;
      this.setState({vehicleTypeInvalid: true});
    } else {
      this.setState({vehicleTypeInvalid: false});
    }
    if(this.state.ticket === null || this.state.ticket.length<0) {
      invalid = true;
      this.setState({ticketInvalid: true});
    } else {
      this.setState({ticketInvalid: false});
    }
    if(invalid===true){
      return false;
    }
    return true;
  }
  register(){
    if (!this.valid_field()) {
      return false;
    }
    this.setState({loading: true, visible: true});
    let url = BaseService.ENDPOINT + 'access-point/ticket';
    var form = new FormData();
    form.append("plate", this.state.plate);
    form.append("repeat_plate", this.state.confirm_plate);
    form.append("document", this.state.document);
    form.append("phone", this.state.phone);
    form.append("email", this.state.email);
    form.append("ticket", this.state.ticket.id);
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Authorization' : 'Bearer ' + this.props.user.access_token,
      }),
      body : form
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
          page_name: 'Ticket Pago',
          feedback_plate: json.data.plate,
          feedback_period: json.data.period,
          feedback_date: json.data.date,
          feedback_time_start: json.data.time_start,
          feedback_time_stop: json.data.time_stop,
          feedback_value: json.data.value,
          show_form: false,
          show_success: true,
          loading: false,
          message: '',
          visible: false,
          ticket: null,
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      }else{
        this.setState({
          loading: true,
          message: json.return.message,
        });
      }
    });
  }
  getUser(){
    const url = BaseService.ENDPOINT + 'access-point/user';
    return fetch(url, {
      method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Authorization' : 'Bearer ' + this.props.user.access_token,
        })
      })
    .then((response) => response.json())
    .then((json) => {
      if(json.return.code === 1){
        this.setState({
          credit: json.data.credit,
        });
      }else if(json.return.code === '-403'){
        window.location = '/';
      } else {
        this.setState({
          credit: '0',
        });
      }
    });
  }
  render() {
    return (
      <Page
        title={this.state.page_name}
        className="VenderPage">
        <div className="" hidden={!this.state.show_form}>
          <Label className="sub-title-tickets">Você possui: <span className="sub-title-tickets-credit">R$ {this.state.credit},00 </span></Label>
          <Row>
            <Col md="3" sm="0" xs="0"></Col>
            <Col md="6" sm="12" xs="12">
             <Form>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <FormGroup>
                      <Input
                        type="text"
                        maxLength={8}
                        name="plate"
                        placeholder={Strings.plate}
                        value={this.state.plate}
                        onChange={this.handleInputChange}
                        invalid={this.state.plateInvalid}>
                      </Input>
                      <FormFeedback>{Strings.required_field}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12" xs="12">
                    <FormGroup>
                      <Input
                        type="text"
                        maxLength={8}
                        name="confirm_plate"
                        placeholder={Strings.confirm_plate}
                        value={this.state.confirm_plate}
                        onChange={this.handleInputChange}
                        invalid={this.state.confirmPlateInvalid}>
                      </Input>
                      <FormFeedback>{Strings.required_field}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <SelectAPI
                      name="vehicle_type"
                      placeholder={"Selecione o "+Strings.vehicle_type}
                      access_token={this.props.user.access_token}
                      api="access-point/vehicle-types"
                      selectedOption={this.handleInputChange}
                      value={this.state.vehicle_type}
                      invalid={this.state.vehicleTypeInvalid}
                      formFeedbackMsg={Strings.required_field}
                      multi={false} />
                  </Col>
                  <Col md="6" sm="12" xs="12">
                    <CustomInput
                      invalid={this.state.documentInvalid}
                      mask={this.state.mask_document}
                      name="document"
                      placeholder='CPF/CNPJ'
                      value={this.state.document}
                      onChange={(value) => {
                        this.setState({
                          document: value,
                          mask_document: (value.length>14?'00.000.000/0000-00':'000.000.000-000')
                        });
                      }}
                      unmask={false}
                      invalid_message={Strings.required_field}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12" xs="12">
                    <CustomInput
                      invalid={this.state.phoneInvalid}
                      mask={this.state.mask_phone}
                      name="phone"
                      placeholder={Strings.cel_phone}
                      value={this.state.phone}
                      onChange={(value) => {
                        this.setState({
                          phone: value,
                          mask_phone: (value.length>14?'(00) 00000-0000':'(00) 0000-00000')
                        });
                      }}
                      unmask={false}
                      invalid_message={Strings.required_field}
                    />
                  </Col>
                  <Col md="6" sm="12" xs="12">
                    <FormGroup>
                      <Input
                        type="text"
                        maxLength={50}
                        name="email"
                        placeholder={Strings.email}
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        invalid={this.state.emailInvalid}>
                      </Input>
                      <FormFeedback>{Strings.required_field}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md="3" sm="0" xs="0"></Col>
          </Row>
          <Row>
            <Col md="1" sm="0" xs="0"></Col>
            <Col md="10" sm="12" xs="12">
            <div style={{display : this.state.vehicle_type == null ? 'none' : 'inline'  }}>
              <ShowTickets
                sell={true}
                user={this.props.user}
                vehicle_type={this.state.vehicle_type == null ? { id : 1} : this.state.vehicle_type }
                FormInsert={this.FormInsert}
                ticketInvalid={this.state.ticketInvalid}
                ticket={(id) => this.setState({ticket: id})}
                deleteAction={this.deleteAction}
                formFeedbackMsg={Strings.required_field}
                reload={this.state.reload}
              />
              </div>
            </Col>
            <Col md="1" sm="0" xs="0"></Col>
          </Row>
          <Row>
            <Col md="5" sm="0" xs="0"></Col>
            <Col md="2" sm="0" xs="0">
              <Button className="marginBottom100" style={{width: '100%'}} onClick={this.register}>OK</Button>
            </Col>
            <Col md="5" sm="0" xs="0"></Col>
          </Row>
        </div>
        <div className="pag-success-ticket" hidden={!this.state.show_success}>
          <Row>
            <Col>
              <Row>
                <Col md="12" sm="12" xs="12" className="box-sucesso-ticket" style={{backgroundImage: "url("+BoxBackgroundWithSeparator+")"}}>
                  <Row>
                    <Col md="12" sm="12" xs="12" className="marginTop60">
                      <Label>{Strings.title_pag_success_ticket}</Label>
                      <p>{this.state.feedback_plate}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="6" xs="12">
                    <Label>{Strings.period_pag_success_ticket}</Label>
                      <p>{this.state.feedback_period}</p>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                    <Label>{Strings.day_pag_success_ticket}</Label>
                      <p>{this.state.feedback_date}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="6" xs="12">
                    <Label>{Strings.time_start_pag_success_ticket}</Label>
                      <p>{this.state.feedback_time_start}</p>
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <Label>{Strings.time_stop_pag_success_ticket}</Label>
                      <p>{this.state.feedback_time_stop}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" sm="12" xs="12" className="marginTop60 marginBottom50">
                      <span>{Strings.values_pag_success_ticket} {this.state.feedback_value},00</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="box-buttons">
                <Col md="12" sm="12" xs="12" className="marginBottom100">
                  <Button color="secondary" onClick={() => this.toggle(false)}>Ok</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.state.visible}>
          <Loader
            body={ModalBody}
            hidden={!this.state.loading}
            message={this.state.message}
            color_message={this.state.color_message}
            onClose={() => {this.toggle(true); this.setState({loading : false, message: '', color_message: ''});}}
            onCloseSuccess={() => {this.toggle(true); this.setState({loading : false, message: '', color_message: ''});}}
          />
        </Modal>
      </Page>
    );
  }
};

function mapStateToProps(state) {
  const currentUser = loginSelectors.getUser(state);
  return {
    user : currentUser
  };
}
export default connect(mapStateToProps)(VenderPage)
