import logoViaPlus from 'assets/img/logo/logo_via_plus.png';
import React from 'react';
import {
  MdPermIdentity,
  MdShoppingCart,
  MdLocalShipping,
  MdEventNote,
  MdExitToApp,
  MdMonetizationOn,
} from 'react-icons/lib/md';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import * as loginActions from '../../store/login/actions';
import * as loginSelectors from '../../store/login/reducer';
import { connect } from 'react-redux';

const navItems = [
  { id: 1, to: '/conta',        name: 'Conta',        exact: true, Icon: MdPermIdentity },
  { id: 2, to: '/comprar',      name: 'Comprar',      exact: false, Icon: MdShoppingCart },
  { id: 3, to: '/vender',       name: 'Vender',       exact: false, Icon: MdLocalShipping },
  //{ id: 4, to: '/venda-direta', name: 'Venda Direta', exact: false, Icon: MdLocalShipping },
  { id: 4, to: '/historico',    name: 'Histórico',    exact: false, Icon: MdEventNote },
  { id: 5, to: '/recarga',      name: 'Recarga',      exact: false, Icon: MdMonetizationOn }
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      selected_menu: '',
    };
    this.isActive = this.isActive.bind(this);
    this.logout = this.logout.bind(this);
  }
  isActive(TagId, Folder){
    return (Folder===window.location.pathname?'active':(TagId===this.state.selected_menu?'active':''));
  }
  setFilter = TagId => () => {
    this.setState({
      selected_menu: TagId,
    });
  }
  logout() {
    this.props.dispatch(loginActions.logout());
  }
  redirectPage = link => () => {
    window.location.ref = link;
  }
  render() {
    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Navbar>
            <img
              src={logoViaPlus}
              width="190"
              height="60"
              className="pr-2"
              alt="Eco Parking"
            />
          </Navbar>
          <Nav vertical>
            {navItems.map(({ id, to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className={this.isActive(id, to)}
                  onClick={this.setFilter(id)}
                  tag={NavLink}
                  to={to}
                  activeClassName=""
                  exact={exact}>
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem key={4} className={bem.e('nav-item')}>
              <a id="navItem-Logout-4"
                className='nav-link'
                onClick={this.logout} 
                title="Logout"
                alt="Logout"
                href="/">
                <MdExitToApp className={bem.e('nav-item-icon')} />
                <span className="">Logout</span>
              </a>
            </NavItem>
          </Nav>
        </div>
      </aside>
    );
  }
}

// which props do we want to inject, given the global store state?
function mapStateToProps(state) {
  return {
    user : loginSelectors.getUser(state)
  };
}
export default connect(mapStateToProps)(Sidebar);